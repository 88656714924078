<template>
  <v-app>
    <v-app-bar app elevation="0" dark class="f3-app-bar">
      <v-app-bar-nav-icon @click.stop="mini = !mini"></v-app-bar-nav-icon>

      <v-toolbar-title class="d-flex">
        <div class="text-h5 font-weight-medium ms-3">F3 Admin</div>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- <v-menu
        right
        bottom
        :offset-y="true"
        rounded="1"
        v-if="notifications.length > 0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge
              bottom
              color="red"
              :content="notifications.length"
              offset-x="10"
              offset-y="10"
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list width="200">
          <v-list-item
            three-line
            v-for="notification in notifications"
            :key="notification.id"
            @click="notification_redirect(notification.link, notification.id)"
          >
            <v-list-item-content class="pb-0">
              <v-list-item-title>{{ notification.title }}</v-list-item-title>
              <v-list-item-subtitle>{{
                notification.message
              }}</v-list-item-subtitle>
              <v-divider class="mt-3"></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu right bottom :offset-y="true" rounded="1" v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </template>
        <v-list width="200">
          <v-list-item one-line>
            <v-list-item-content>
              <v-list-item-title>No notifications</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-menu right bottom :offset-y="true" rounded="1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- <v-list-item @click="profile = true">
            <v-list-item-title
              ><v-icon> mdi-cog </v-icon> Settings</v-list-item-title
            >
          </v-list-item>
          <v-divider class="mx-2"></v-divider> -->
          <v-list-item @click="logout">
            <v-list-item-title
              ><v-icon> mdi-logout </v-icon> Logout</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <div class="nav">
        <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          fixed
          permanent
          class="grey lighten-5"
          :mini-variant-width="mini_width"
        >
          <v-list nav class="mt-15">
            <div v-for="item in items" :key="item.title">
              <v-list-group
                active-class="primary--text blue-grey lighten-5"
                :prepend-icon="item.icon"
                no-action
                v-if="item.items"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <div v-for="child in item.items" :key="child.title">
                  <v-list-item
                    link
                    :to="child.path"
                    @click.stop="mini = !mini"
                    active-class="primary--text"
                    class="ps-10"
                  >
                    <v-list-item-content class="ps-8">
                      <v-list-item-title>{{ child.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list-group>

              <v-list-item
                link
                :to="item.path"
                @click.stop="mini = !mini"
                active-class="primary--text"
                class="my-2"
                v-else
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-navigation-drawer>
      </div>
      <div>
        <router-view />
      </div>
    </v-main>
    <div class="px-5 font-weight-light text-caption grey--text py-2 text-right">
      © 2023, Keture (Pvt) Ltd. All rights reserved.
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    mini_width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        default:
          return 56;
      }
    },
  }),
  components: {
    //
  },
  data: () => ({
    notifications: [],
    mini: true,
    drawer: true,
    user: null,
    items: [
      {
        icon: "mdi-view-dashboard",
        name: "dashboard",
        title: "Dashboard",
        path: "/",
      },
      {
        icon: "mdi-account-multiple ",
        name: "users",
        title: "Users",
        path: "/users",
      },
      {
        icon: "mdi-fish",
        name: "fishes",
        title: "Fishes",
        path: "/fishes",
      },
      {
        icon: "mdi-store",
        name: "auctions",
        title: "Auctions",
        path: "/auctions",
      },
      // {
      //   icon: "mdi-speedometer",
      //   name: "log",
      //   items: [
      //     {
      //       title: "New Entry",
      //       path: "/running-log/new-entry",
      //     },
      //     {
      //       title: "History",
      //       path: "/running-log/history",
      //     },
      //   ],
      //   title: "Running Log",
      // },
    ],
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({
        name: "PageAuthSignin",
      });
    },
  },
};
</script>

<style>
.nav {
  position: absolute;
  height: fit-content;
  z-index: 1;
}
.f3-app-bar {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.5) 0%,
    rgba(12, 186, 189, 0.5) 100%
  );
}

.f3-title-bar {
  background: rgb(1, 128, 161);
  background: linear-gradient(90deg, rgb(1, 70, 88) 0%, rgb(0, 94, 104) 100%);
  color: white;
}

.f3-action-bar {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgb(214, 216, 216) 0%,
    rgb(231, 231, 231) 100%
  );
}
</style>